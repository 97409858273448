/* Nav */

.nav-767,
.nav-768 {
  display: none !important;
}

@media screen and (min-width: 768px) {
  .nav-768 {
    display: flex !important;
  }
}

@media screen and (max-width: 767px) {
    .nav-767 {
    display: flex !important;
  }
}

/* Content */

@media screen and (max-width: 768px) {
  .form-progress__wrap {
    margin: 0 10px;
    padding: 0 10px;
  }

  .userchoice--card {
    text-align: left;
    margin: 40px 0 20px;
  }

  .userchoice--card__label {
    color: #2E7CD1;
    font-weight: bold;
    font-size: 14px;
    margin: 20px 0 10px;
  }

  .userchoice--card__choice-text {
    color: #5B5B5B;
    font-size: 12px;
    font-weight: 500;
  }

  .pricing--total_section {
    border-top: 1px solid #EDEDED; 
    border-bottom: 1px solid #EDEDED; 
  }

  .pricing--total_text {
    margin: 20px 0;
    color: #ffffff;
    background-image: linear-gradient(115.58deg, rgba(92, 201, 172, 0.83) 0%, rgba(46, 124, 209, 0.86) 100%);
    border-radius: 4px;
    padding: 32px;
    font-size: 16px;
    font-weight: bold;
  }

  .pricing--total_text span {
    font-size: 14px;
  }

  .pricing--footer_text {
    color: #979797;
    font-size: 12px;
    left: -40px;
    width: 106vw;
    margin: 20px 0 60px;
    padding: 0 20px 20px;
    position: relative;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
  }

  .cta-submit__wrapper {
    margin: 60px 0;
    padding: 0;
    border: 0;
  }

  .page-content__area .faq-section h3 {
    margin: 40px 0;
  }

}

@media screen and (max-width: 480px) {
  .form-progress__step {
    visibility: hidden;
  }
  
  .form-progress__step::before {
    visibility: visible;
  }
  
  .form-progress__step-title_mobile {
    color: #5B5B5B;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    margin: 20px auto 30px;
  }
  
  .page-content__area {
    margin: 30px auto 10px;
  }
  
  .page-content__area h3 {
    margin: 30px auto 10px;
  }
}