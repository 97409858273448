header {
  background-color: #4188d5;
  text-align: center;
}

header.checkout-header {
  color: #fff;
}

#app {
  max-width: 780px;
  margin: auto;
}

.app_wrap {
  max-width: 780px;
  margin: auto;
}

.masthead {
  text-align: center;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  margin: 20px 0;
}

.masthead h2 {
  color: #979797;
  font-weight: bold;
  font-size: 24px;
  margin: 30px auto;
}

.form-progress__wrap {
  text-align: center;
  color: #EDEDED;
  margin: 0 10px;
}

.form-progress__step {
  font-size: 12px;
  font-weight: bold;
  margin: 1px;
  padding: 0;
}

.form-progress__step:first-child::before {
  border-radius: 8px 0 0 8px;
}

.form-progress__step:last-child::before {
  border-radius: 0 8px 8px 0;
}

.form-progress__step::before {
  background-color: #5ecaae;
  content: '';
  display: block;
  margin: 20px 0 10px;
  height: 8px;
}

.form-progress__step.active {
  color: #595959;
}

.form-progress__step.active::before {
  background-color: linear-gradient(95.22deg, #5BC9AC 0%, rgba(91, 201, 172, 0.68) 100%);
}

.form-progress__step.active ~ *::before {
  background-color: #ededed;
}


/* page */

.page-content__area {
  margin: 40px auto;
  color: #949494;
  max-width: 460px;
}

.page-content__area.auto-width {
  max-width: 100%;
}

.page-content__choices-area {
  margin: 40px 0 20px;
}

.page-content__area h3 {
  color: #595959;
  font-size: 24px;
  font-weight: bold;
  margin: 40px 0 30px;
}

.small-text {
  font-size: 12px;
  margin: 20px 0;
}

.choice-button {
  background: linear-gradient(95.22deg, #5BC9AC 0%, rgba(91, 201, 172, 0.68) 100%);
  border: 0;  
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  display: block;
  padding: 12px 0 13px;
  height: auto;
  margin: 0 0 20px;
}

.choice-button:hover,
.choice-button.selected {
  color: #fff;
  background: #37a386;
}

input[type="text"] {
  border: 1px solid #C5C5C5;
  padding-left: 2.5em;
  font-size: 12px;
  height: 42px;
}

.has-icons-left .icon.is-left {
  top: 5px;
}

input[type="radio"] {
  margin: 10px;
  visibility: hidden;
  position: absolute;
}

input[type="radio"] ~ span {
  content: '';
  border: 1px solid #5ecaae;
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  position: relative;
  visibility: visible;
  top: 6px;
  right: -3px;
  margin: 0 15px 0 5px;
}

input[type="radio"]:checked ~ span {
  background: linear-gradient(95.22deg, #5BC9AC 0%, rgba(91, 201, 172, 0.68) 100%);
}


.page-content__results-table {
  border-radius: 4px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 100%;
}

.page-content__results-table tr th {
  background: #2E7CD1;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  padding: 20px;
  width: 75%;
  min-width: 120px;
  border-left: 1px solid #4A8ED9;
} 

.page-content__results-table tr th:first {
  border-left: none;
}

.page-content__results-table tr td {
  font-weight: 500; 
  color: #5B5B5B;
  font-size: 12px;
  padding: 20px;
  border-left: 1px solid #EDEDED;
  border-bottom: 1px solid #EDEDED;
}

.page-content__results-table .total-row td {
  font-size: 12px;
  font-weight: normal;
  color: #979797;
  text-align: right;
}

.page-content__results-table .total-row td.totalCol {
  background: linear-gradient(110.25deg, rgba(92, 201, 172, 0.83) 0%, rgba(46, 124, 209, 0.86) 100%);
  border-radius: 4px;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  vertical-align: middle;
}

.cta-submit__wrapper {
  margin: 40px 0;
  padding: 80px 120px;
  border-radius: 20px;
  border: 2px dashed rgba(46, 124, 209, 0.86) ;
}

.cta-button {
  font-size: 16px;
  padding: 22px;
  font-weight: bold;
  margin: 0;
}


/* FAQ */

.faq-question {
  color: #5B5B5B;
  cursor: pointer;
  font-family: Roboto;
  font-weight: 500;
  font-size: 16px;
}

.faq-icon {
  color: #5FCAAE;
  text-align: right;
  font-size: 18px;
}

.faq-answer-text {
  background: #f3f3f3;
  border-radius: 4px;
  margin: 10px 0 30px;
  padding: 20px 30px;  
}


.input-error {
  animation: 0.75s linear 0s infinite alternate animate-border;
}

@keyframes animate-border {
  0% { border: 1px solid #C5C5C5}
  100% { border: 1px solid #e50000}
}

/* footer */

footer {
  background: url("//www.biznessmove.fr/wp-content/uploads/2018/12/createur-site-e-commerce-footer.png") no-repeat 0 0;
  background-size: cover;
  color: #fff;
  font-size: 14px;
  line-height: 23px;
}

footer h4 {
  font-size: 24px;
  margin-bottom: 1em;
}

footer li {
  list-style: disc;
}

.footer-links {
  background: #000;
  margin: 40px 0 0;
}

.footer-links a {
  font-size: 16px;
  color: #fff;
}


/* Checkout Page */
.checkout--container {
  max-width: 896px;
  margin: auto;
}


.checkout-usp--bullets__container {
  margin: 30px 0;
}

.checkout-usp--bullets__container li {
  margin: 0 0 15px;
}

.checkout--form-row {
  margin-bottom: 15px;
  display: flex;
}

.checkout--form-row label {
  width: 180px;
  display: inline-block;
}

.checkout--form-row input[type="text"] {
  padding-left: 10px;
  margin-left: 0;
  max-width: auto;
  flex-grow: 1
}

.checkout--container h3 {
  margin: 30px 0;
}

.checkout--button {
  width: 100%;
  display: block;
  box-sizing: border-box;
  margin: 2em 0;
  font-size: 1.5em;
  padding: 1em 0;
  height: auto;
  line-height: 1;
}

.checkout-usp--bullets__container li {
  margin: 30px 0;
  line-height: 28px;
}

@media screen and (max-width: 480px) {
  .checkout--form-row input[type="text"] {
    width: 100%;
    margin: 5px 0 10px;
  }
}